import KlaviyoForms from "~/components/KlaviyoForms";
import { getCommonSliceProps } from "~/external/prismic";
import connectSlice from "~/lib/connectSlice";

function mapSliceToProps(slice: any) {
  const { primary, items } = slice;

  const props = {
    ...getCommonSliceProps(slice),
    id: primary.id,
    title: primary.title,
  };

  return props;
}

export default connectSlice(mapSliceToProps)(KlaviyoForms);
