import styled from "styled-components";
import Block, { BlockContent } from "~/components/Block";
import { getRichText } from "~/external/prismic";

const KlaviyoFormsEmbedTitle = styled.div`
  text-align: center;
  h1 {
    margin-top: 0;
  }
`;

type KlaviyoFormsEmbedProps = {
  id: string;
  title?: any;
  spacingTop?: number;
  spacingBottom?: number;
  backgroundColor?: string;
  color?: string;
};

export default function KlaviyoFormsEmbed(props: KlaviyoFormsEmbedProps) {
  const { id, title, spacingTop, spacingBottom, backgroundColor, color } = props;

  return (
    <Block
      spacingTop={spacingTop}
      spacingBottom={spacingBottom}
      backgroundColor={backgroundColor}
      color={color}
    >
      <BlockContent>
        <KlaviyoFormsEmbedTitle>{getRichText(title)}</KlaviyoFormsEmbedTitle>

        <div className={id} />

        {/* <KlaviyoForms {...props} /> */}
      </BlockContent>
    </Block>
  );
}
